import config from "@/config";

import { get, del, post, patch } from "@/requests"

export async function getCategoriesData() {
    return get(`${config.BACK_IP}/categories`)
}
export async function getCategoryData(id) {
    return get(`${config.BACK_IP}/category/${id}`)
}

export async function createNewCategory(newCategory) {
    return post(`${config.BACK_IP}/category`, newCategory)
}

export async function updateCategory(category, id) {
    return patch(`${config.BACK_IP}/category/${id}`, category)
}

export function deleteCategory(id) {
    return del(`${config.BACK_IP}/category/${id}`)
}