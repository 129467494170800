<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable
          :fields="fields"
          :items="categories"
          :actions="actions"
          title="Categorías"
          labelItemPerPage="Categorías por página:"
          :addBtn="{
            enable: $store.state.isAdmin,
            text: 'Añadir categoría',
            event: 'addElement',
          }"
          @addElement="addCategory"
          @editInfo="editCategory"
          @showDeleteModal="handleModal"
          :noResults="'No se han encontrado categorías'"
        />

        <DeleteModal
          :modalContent="modalContent"
          @delete="deleteCategoryData(categoryId)"
          :showModal="deleteModal"
          @closeModal="closeModal"
        />
      </CCol>
    </CRow>
  </div>
</template>
  
  <script>
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getCategoriesData, deleteCategory } from "@/services/categories";
import { generateField } from "@/utils/utils";
export default {
  name: "categoriesTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      categories: [],
      category: {},
      categoryId: 0,
      categoryName: "",
      loading: true,
      deleteModal: false,
      fields: [
        generateField("name", "Nombre", { styleData: { textAlign: "center" } }),
        generateField("psy_name", "Nombre técnico", { styleData: { textAlign: "center" } }),
        generateField("type", "Tipo", { styleData: { textAlign: "center" } }),
        generateField("actions", "Acciones", { sorter: false, filter: false }),
      ],
      actions: [
        {
          key: "info",
          label: "Información",
          event: "editInfo",
          classButton: "btn btn btn-info btn-success btn-sm btn-pill",
        },
        {
          key: "delete",
          label: "Eliminar",
          isAdmin: !this.$store.state.isAdmin,
          event: "showDeleteModal",
          classButton: "btn btn-delete btn-sm btn-pill",
        },
      ],
      modalContent: {
        title: "Eliminar categoría",
        textBody: "",
        buttons: [
          {
            textButton: "Cerrar",
            color: "secondary",
            event: "closeModal",
          },
          {
            textButton: "Aceptar",
            color: "danger",
            event: "delete",
          },
        ],
      },
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    /**
     * Función en la que se obtienen las categorías
     *
     * @returns {object[]} Las categorías obtenidas.
     */
    getCategories() {
      getCategoriesData()
        .catch((error) => {
          this.$store.state.errorAlert = {
            status: true,
            msg: error.toString(),
          };
          this.loading = false;
        })
        .then((result) => {
          this.categories = result;
          this.categories.forEach((category) => {
            category.id = category.id;
            category.name = category.name;
            category.psy_name = category.psy_name;
            category.type = category.type.name;
          });
          this.loading = false;
        });
    },
    /**
     * Este método se llama cuando el usuario pulsa el botón Crear nueva categoría y redirige a la vista con el formulario para poder crear una.
     */
    addCategory() {
      this.$router.push(`/categories/create/`);
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar la categoría.
     */
    handleModal(item) {
      this.categoryId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar la categoría: <strong>"${item.name}</strong>"? Esta acción no se puede deshacer.`
      this.deleteModal = true;
    },
    /**
     * Este método se llama cuando el usuario pulsa el botón Editar y redirige a la vista con el formulario para poder editar una.
     */
    editCategory(item) {
      this.$router.push(`/categories/info/${item.id}`);
    },
    /**
     * Este metodo eliminará la categoría, mandando por la URL el id
     *
     * @param {number} id - El id de la categoría a eliminar
     */
    deleteCategoryData(id) {
      deleteCategory(id).then(() => {
        this.getCategories();
      });
      this.deleteModal = false;
    },
    /**
     * Función para cerrar el Modal.
     */
    closeModal() {
      this.deleteModal = false;
    },
  },
};
</script>